export function getUrl(url) {
  var baseUrl =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development"
      ? "http://localhost:3000"
      : "https://www.riktam.com"

  switch (url) {
    /////////////////////////////////////////////////////////login/////////////////////////////////////////////////////////
    case "webform":
      return baseUrl + "/form_submission"
    default:
      return baseUrl
  }
}
