import React, { Component } from "react"
import Snackbar from "@material-ui/core/Snackbar"
import SnackbarContent from "@material-ui/core/SnackbarContent"

import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"

const snackbarDuration = 2500

export default class CustomSnackbar extends Component {
  render() {
    return (
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        open={this.props.isSnackbarOpen}
        autoHideDuration={snackbarDuration}
        onClose={this.props.closeSnackbar}
      >
        <SnackbarContent
          aria-describedby="client-snackbar"
          style={{ backgroundColor: this.props.snackbarColor }}
          message={
            <span
              id="client-snackbar"
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {this.props.snackbarIcon && (
                <this.props.snackbarIcon
                  style={{ fontSize: 20, opacity: 0.9, marginRight: 10 }}
                />
              )}
              <span id="client-snackbar">{this.props.snackbarMessage}</span>
            </span>
          }
          action={[
            <IconButton
              key="close"
              aria-label="close"
              color="inherit"
              onClick={this.props.closeSnackbar}
            >
              <CloseIcon style={{ fontSize: 20 }} />
            </IconButton>,
          ]}
        />
      </Snackbar>
    )
  }
}
