import React, { Component } from "react"
import Home from "../components/Description/Home/Home"
import Layout from "../components/Layout"

export default function Index() {
  return (
    <Layout>
      <Home />
    </Layout>
  )
}
