import React, { Component } from "react"
import projectBG from "../../../images/projectBG.png"
import enterpriseApps from "../../../images/enterpriseApps.png"
import mobileApps from "../../../images/mobileApps.png"
import backend from "../../../images/backend.png"
import { COLORS } from "../../../utils/Colors"

export default class Projects extends Component {
  getBox(imgStyle, src, content) {
    return (
      <div className="d-inline-block col-12 col-md-4">
        <div className="col-12 d-flex justify-content-center">
          <div className="col-9">
            <div className="d-flex justify-content-center">
              <img style={imgStyle} src={src} alt="" />
            </div>
            <div style={styles.projectDescription}>{content}</div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    return (
      <div className="row" style={styles.container}>
        <img
          className="col-12 tiltRight"
          style={styles.bg}
          src={projectBG}
          alt=""
        />
        <div style={styles.subContainer}>
          <span className="d-inline-block col-1" />
          <div className="d-inline-block col-10" style={styles.heading}>
            Riktam is a Software Engineering company that brings world class
            projects to life. Our engineers compete with the best in the world,
            we choose our clients carefully, and look for stable, long term
            relationships
          </div>
          <span className="d-inline-block col-1" />
          <div style={{ paddingTop: 80 }}>
            {this.getBox(
              styles.image1,
              enterpriseApps,
              "We build consumers and enterprise applications"
            )}
            {this.getBox(
              styles.image2,
              mobileApps,
              "We work on web, mobile-web and native mobile"
            )}
            {this.getBox(
              styles.image3,
              backend,
              "We build backend tech that drives highly scaled services"
            )}
          </div>
        </div>
      </div>
    )
  }
}

var styles = {
  container: {
    background: COLORS.PROJECTS_BACKGROUND,
    // backgroundColor: "lightpink",
  },
  subContainer: {
    paddingTop: 60,
    paddingBottom: 50,
  },
  bg: {
    opacity: 0.89,
    padding: 0,
    paddingTop: 20,
    position: "absolute",
  },
  heading: {
    textAlign: "center",
    color: COLORS.DARK_TEXT_COLOR,
    fontFamily: "SFProBold",
    // fontSize: 14,
  },
  image1: {
    width: 200,
    height: 150,
  },
  image2: {
    width: 160,
    height: 150,
  },
  image3: {
    width: 200,
    height: 150,
  },
  projectDescription: {
    color: COLORS.PROJECT_DESCRIPTION,
    fontFamily: "SFProBold",
    textAlign: "center",
    paddingTop: 50,
    paddingBottom: 80,
    // fontSize: 14,
  },
}
