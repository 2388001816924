import React, { Component } from "react"
import { COLORS } from "../../../utils/Colors"
import Intro from "./Intro"
import Projects from "./Projects"
import Inquiry from "./Inquiry"
import ToolsAndFrameworks from "./ToolsAndFrameworks"

export default class Home extends Component {
  render() {
    return (
      <div className="container-fluid">
        <Intro />
        <Projects />
        <Inquiry />
        <ToolsAndFrameworks />
      </div>
    )
  }
}
