import React, { Component } from "react"
import { COLORS } from "../../../utils/Colors"
import mobile from "../../../images/mobile.png"
import laptop from "../../../images/laptop.png"

export default class Intro extends Component {
  render() {
    return (
      <div className="row" style={styles.container}>
        <div className="col-12 p-0" style={styles.subContainer}>
          <span className="col-0 col-md-1" />
          <div
            className="d-flex justify-content-end col-12 col-md-5"
            style={styles.imageContainer}
          >
            <div className="pr-2 col-12 col-sm-10 col-lg-8">
              <img className="img-fluid" src={mobile} style={styles.mobile} />
              <img className="img-fluid" src={laptop} style={styles.laptop} />
            </div>
          </div>
          <div className="p-0 col-12 col-md-7 float-md-left">
            <span className="d-inline-block col-0 col-sm-2" />
            <div className="d-inline-block col-12 col-sm-10">
              <div
                style={{
                  color: COLORS.THEME_COLOR,
                  fontSize: 22,
                  fontFamily: "SFProHvy",
                  lineHeight: 1.2,
                  // letterSpacing: -0.4,
                }}
                className="pl-0 d-inline-block col-sm-10"
              >
                BLEEDING EDGE TECH
                <div
                  style={{
                    fontSize: 46,
                    // letterSpacing: 0.66,
                    fontWeight: "normal",
                    fontFamily: "SFProReg",
                  }}
                >
                  WHAT WE DO
                </div>
              </div>
              <div style={styles.contentDescription}>
                <div>
                  Engineers at Riktam build Web and Mobile Applications using
                  the latest technologies, for startups around the world.
                </div>
                Our work spans online marketplaces, retail, logistics, sports,
                dev-ops, ad and content serving, data and visualisation.
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

var styles = {
  container: {
    backgroundColor: COLORS.INQUIRY_BACKGROUND,
    paddingTop: 40,
    paddingBottom: 50,
    // height: 400,
  },
  subContainer: {
    // backgroundColor: "red",
  },
  imageContainer: {
    float: "right",
  },
  mobile: {
    height: 200,
    // width: 100,
    position: "absolute",
    // left: 65,
    // bottom: 10,
    top: 30,
    zIndex: 1,
  },
  laptop: {
    height: 280,
    // width: 300,
    position: "relative",
    left: 20,
    zIndex: 2,
  },
  heading: { color: COLORS.THEME_COLOR, fontFamily: "SFProHvy" },
  question: {
    position: "relative",
    bottom: 18,
    color: COLORS.THEME_COLOR,
    fontFamily: "SFProReg",
  },
  contentDescription: {
    color: COLORS.DESCRIPTIONS,
    // fontSize: 14,
    paddingTop: 30,
    fontFamily: "SFProSmBold",
    // backgroundColor: "yellow",
  },
}
