import React, { Component } from "react"

import { COLORS } from "../../../utils/Colors"

export default class ToolsAndFrameworks extends Component {
  getRow(label, technologies, isSpecialRow) {
    return (
      <div className="col-12" style={styles.eachType}>
        <div
          className="d-inline-block col-12 col-sm-5 col-md-4"
          style={{
            paddingTop: 18,
            float: "left",
            fontFamily: "SFProBold",
          }}
        >
          <span className="d-inline-block col-2" />
          <div className="d-inline-block col-8">{label}</div>
        </div>
        <div className="d-inline-block p-0 col-12 col-sm-7 col-md-8">
          {!isSpecialRow ? (
            <span style={styles.technologyView}>
              {technologies.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "inline-block",
                    paddingLeft: 8,
                    paddingRight: 10,
                    fontFamily: "SFProMed",
                  }}
                >
                  {item}
                </div>
              ))}
            </span>
          ) : (
            this.getSpecialRow()
          )}
        </div>
      </div>
    )
  }
  getSpecialRow() {
    // labels = ["iOS", "Android", "Native"]
    return (
      <div
        className="p-0 col-12 col-md-12"
        style={{ display: "inline-block p-0" }}
      >
        <div className="col-12">
          {this.getEachSpecialRow("iOS", ["ObjectiveC", "Swift"])}
          {this.getEachSpecialRow("Android", ["Java", "Kotlin"])}
          {this.getEachSpecialRow("Hybrid", ["ReactNative"])}
        </div>
      </div>
    )
  }
  getEachSpecialRow(label, technologies) {
    return (
      <div className="d-inline-block p-0 col-12 col-md-4" style={{ left: 22 }}>
        <div
          className="col-6 col-sm-12 col-lg-4 text-lg-right"
          style={{
            display: "inline-block",
            float: "left",
            paddingTop: 20,
          }}
        >
          {label}
        </div>
        <span
          className="col-6 col-sm-12 col-lg-8"
          style={{
            ...styles.technologyView,
            float: "left",
            // padding: 0,
            paddingLeft: 10,
            textAlign: "center",
            display: "inline-block",
          }}
        >
          {technologies.map((item, index) => (
            <div
              key={index}
              style={{
                display: "inline-block",
                paddingLeft: 5,
                paddingRight: 0,
              }}
            >
              {item}
            </div>
          ))}
        </span>
      </div>
    )
  }
  render() {
    return (
      <div className="row" style={styles.container}>
        <div className="col-12">
          <div className="p-0 col-12">
            <span className="d-inline-block col-0 col-sm-1" />
            <div className="p-0 d-inline-block col-12 col-sm-11">
              <div
                style={{
                  color: COLORS.THEME_COLOR,
                  fontSize: 22,
                  fontFamily: "SFProHvy",
                  lineHeight: 1.2,
                  // letterSpacing: -0.4,
                }}
                className="pl-0 d-inline-block col-sm-10"
              >
                Tech Stacks
                <div
                  style={{
                    fontSize: 46,
                    // letterSpacing: 0.66,
                    fontWeight: "normal",
                    fontFamily: "SFProReg",
                  }}
                >
                  Available
                </div>
              </div>
            </div>
          </div>
          <div style={styles.type}>Server Side</div>
          {/* <div
            className="row col-12"
            style={{
              marginTop: 15,
              marginBottom: 20,
              paddingRight: 0,
            }}
          > */}
          {this.getRow("Web framework", [
            "Django",
            "Flask",
            "AIOHTTP",
            "Zend Framework",
            "Express",
            "Ruby on Rails",
          ])}
          {this.getRow("Programming Language", [
            "Python",
            "PHP",
            "NodeJS",
            "Ruby",
            "Go",
          ])}
          {this.getRow("Database", [
            "MySql",
            "Postgres",
            "MongoDB",
            "Redis",
            "Datastore",
            "Firebase",
            "DynamoDB",
          ])}
          {this.getRow("Web Server", ["Nginx", "Apache2"])}
          {this.getRow("Cloud", ["AWS", "Google Cloud"])}
          {/* </div> */}
          <div style={{ ...styles.type, paddingTop: 25 }}>Client Side</div>
          {/* <div
            className="row col-12"
            style={{
              // paddingTop: 15,
              // paddingBottom: 20,
              paddingRight: 0,
            }}
          > */}
          {this.getRow("Framework", [
            "React",
            "VueJS",
            "Angular",
            "ExtJS",
            "Webix",
          ])}
          {this.getRow("Programming Language", [""], true)}
          {/* </div> */}
        </div>
      </div>
    )
  }
}

var styles = {
  container: {
    backgroundColor: COLORS.PRIMARY_COLOR,
    paddingTop: 50,
    paddingBottom: 30,
    // backgroundColor: "lightblue", //COLORS.PRIMARY_COLOR,
  },
  type: {
    float: "right",
    color: COLORS.TYPE,
    fontSize: 22,
    paddingRight: 80,
    marginBottom: 10,
    display: "inline-block",
    fontFamily: "SFProBold",
  },
  eachType: {
    display: "inline-block",
    color: COLORS.TYPE,
    // backgroundColor: "black",
    // paddingRight: 25,
  },
  technologyView: {
    // borderWidth: 0.8,
    border: "2px solid",
    borderColor: COLORS.TECHNOLOGIES_BORDER_COLOR,
    borderRadius: 30,
    padding: 18,
    float: "right",
  },
}
