import React, { Component } from "react"
import { COLORS } from "../../../utils/Colors"

export default class TextComponent extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(event) {
    var value = event.target.value
    if (this.props.type === "number") {
      var numbers = /^[0-9]+$/
      // var numbers = /^[+0-9]+$/
      if (value === "" || value.match(numbers)) {
        this.props.inputChanged(this.props.placeHolder, value)
      }
    } else {
      this.props.inputChanged(this.props.placeHolder, value)
    }
  }

  render() {
    return (
      <div style={styles.container} className={this.props.className}>
        {this.props.lineType == "singleLine" ? (
          <input
            id={"input" + this.props.type}
            className="col-12"
            // type={this.props.type}
            value={this.props.value}
            onChange={this.handleChange}
            placeholder={this.props.placeHolder}
            style={{ ...styles.input, ...this.props.style }}
          />
        ) : (
          <textarea
            id={"input" + this.props.type}
            className="col-12"
            value={this.props.value}
            onChange={this.handleChange}
            placeholder={this.props.placeHolder}
            style={{ ...styles.input, ...this.props.style }}
          />
        )}
      </div>
    )
  }
}

var styles = {
  container: {
    display: "inline-block",
    // fontSize: 14,
  },
  input: {
    borderRadius: 5,
    border: "1px solid",
    color: COLORS.TYPE,
    borderColor: COLORS.INPUT_BORDER,
    backgroundColor: COLORS.INPUT_BACKGROUND,
    // margin: 5,
    marginTop: 10,
    marginBottom: 10,
    height: 35,
    resize: "none",
    fontFamily: "SFProMed",
  },
}
