import React, { Component } from "react"
import { COLORS } from "../../../utils/Colors"
import TextComponent from "../FormRequirements/TextComponent"

import CheckCircleIcon from "@material-ui/icons/CheckCircle"
import WarningIcon from "@material-ui/icons/Warning"
import ErrorIcon from "@material-ui/icons/Error"
import CustomSnackbar from "../FormRequirements/CustomSnackbar"
import { getUrl } from "../../../network/apis"

export default class Inquiry extends Component {
  constructor(props) {
    super(props)
    this.state = {
      email: "",
      contact: "",
      message: "",

      isSubmittingForm: false,
      isSnackbarOpen: false,
      snackbarIcon: "",
      snackbarMessage: "",
      snackbarColor: "",
    }
    this.submitInquiryForm = this.submitInquiryForm.bind(this)
    this.inputChanged = this.inputChanged.bind(this)
    this.closeSnackbar = this.closeSnackbar.bind(this)
    this.isValidEmail = this.isValidEmail.bind(this)
  }

  isValidEmail() {
    var value = this.state.email
    var pattern = /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i
    if (!value.match(pattern)) {
      this.setState({
        isSnackbarOpen: true,
        snackbarColor: COLORS.SNACKBAR_WARNING,
        snackbarIcon: WarningIcon,
        snackbarMessage: "Please enter a valid email id",
      })
      document.getElementById("inputemail").focus()
      return false
    }
    return true
  }

  areMandatoryFieldsFilled() {
    var required = []
    if (!this.state.email) required.push("Work Email")
    if (!this.state.contact) required.push("Contact Number")
    if (!this.state.message) required.push("Message")
    if (required.length) {
      this.setState({
        isSnackbarOpen: true,
        snackbarIcon: WarningIcon,
        snackbarMessage:
          "Please fill the required field(s) - " + required.join(", "),
        snackbarColor: COLORS.SNACKBAR_WARNING,
      })
      return false
    } else if (!required.length && this.isValidEmail()) {
      return true
    }
    return false
  }

  closeSnackbar() {
    this.setState({
      isSnackbarOpen: false,
    })
  }

  submitInquiryForm() {
    var mandatoryFieldsFilled = this.areMandatoryFieldsFilled()
    if (mandatoryFieldsFilled) {
      this.setState({ isSubmittingForm: true })
      var body =
        "column_10743=" +
        this.state.email +
        "&column_10744=" +
        this.state.contact +
        "&column_10745=" +
        this.state.message +
        "&form_id=12138"
      fetch(getUrl("webform"), {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: body,
      })
        .then(response => {
          return response.json()
        })
        .then(res => {
          var snackbarMessage = "",
            snackbarColor = "",
            snackbarIcon = "",
            isSuccess = true
          if (res.success) {
            snackbarIcon = CheckCircleIcon
            snackbarMessage = "Submitted Successfully"
            snackbarColor = COLORS.SNACKBAR_SUCCESS
          } else {
            snackbarIcon = ErrorIcon
            snackbarMessage = "Submission Failed. Please try again later."
            snackbarColor = COLORS.SNACKBAR_FAIL
            isSuccess = false
          }
          this.setState({
            isSubmittingForm: false,
            isSnackbarOpen: true,
            snackbarIcon: snackbarIcon,
            snackbarMessage: snackbarMessage,
            snackbarColor: snackbarColor,
            email: isSuccess ? "" : this.state.email,
            contact: isSuccess ? "" : this.state.contact,
            message: isSuccess ? "" : this.state.message,
          })
        })
        .catch(err => {
          this.setState({
            isSubmittingForm: false,
            isSnackbarOpen: true,
            snackbarIcon: ErrorIcon,
            snackbarMessage: "Submission Failed. Please try again later.",
            snackbarColor: COLORS.SNACKBAR_FAIL,
          })
          console.log(err)
        })
    }
  }

  inputChanged(type, value) {
    if (type == "Work email") this.setState({ email: value })
    if (type == "Contact Number") this.setState({ contact: value })
    if (type == "Message...") this.setState({ message: value })
  }

  render() {
    return (
      <div className="row" style={styles.container}>
        <div className="col-12" style={styles.subContainer}>
          <div
            className="d-inline-block col-12 col-md-8"
            // style={{ backgroundColor: "red" }}
          >
            <span className="col-0 col-md-2" />
            <div className="col-12 offset-md-1" style={styles.inputs}>
              <TextComponent
                placeHolder="Work email"
                type="email"
                lineType="singleLine"
                className="col-12 col-sm-12 col-md-5 col-lg-5"
                value={this.state.email}
                inputChanged={this.inputChanged}
              />
              <TextComponent
                placeHolder="Contact Number"
                type="number"
                lineType="singleLine"
                className="col-12 col-sm-12 col-md-5 col-lg-5"
                value={this.state.contact}
                inputChanged={this.inputChanged}
              />
            </div>
            <span className="col-0 col-md-1" />
            <div className="col-12 offset-md-1">
              <TextComponent
                placeHolder="Message..."
                type="text"
                lineType="multiLine"
                style={{ height: 80 }}
                className="col-12 col-sm-12 col-md-10 col-lg-10"
                value={this.state.message}
                inputChanged={this.inputChanged}
              />
            </div>
          </div>
          <div
            className="d-inline-block col-12 col-md-4"
            style={styles.submitContainer}
          >
            <div className="d-flex justify-content-center">
              {this.state.isSubmittingForm ? (
                <button className="col-8 col-md-8" style={styles.submitButton}>
                  <span
                    className=" pr-2 spinner-border spinner-border-sm"
                    style={{ marginRight: 10 }}
                  />
                  Sending...
                </button>
              ) : (
                <button
                  className="col-8 col-md-8 submitButton"
                  style={styles.submitButton}
                  onClick={this.submitInquiryForm}
                >
                  Send Inquiry
                </button>
              )}
            </div>
          </div>
        </div>
        <CustomSnackbar
          isSnackbarOpen={this.state.isSnackbarOpen}
          closeSnackbar={this.closeSnackbar}
          snackbarColor={this.state.snackbarColor}
          snackbarIcon={this.state.snackbarIcon}
          snackbarMessage={this.state.snackbarMessage}
        />
      </div>
    )
  }
}

var styles = {
  container: {
    // background: COLORS.HOME_GRADIENT_BACKGROUND,
    backgroundColor: COLORS.INQUIRY_BACKGROUND,
    // height: 250,
    paddingTop: 50,
    paddingBottom: 50,
  },
  subContainer: {
    // backgroundColor: "green",
    // padding: 20,
  },
  inputs: {},
  submitContainer: {
    float: "right",
    // backgroundColor: "yellow",
    paddingTop: 30,
  },
  submitButton: {
    textDecoration: "none",
    fontFamily: "SFProSmBold",
    // backgroundColor: COLORS.THEME_COLOR,
    border: "none",
    color: COLORS.LIGHT_TEXT_COLOR,
    padding: 7,
    paddingLeft: 20,
    paddingRight: 20,
    borderRadius: 8,
  },
}
